<template>
  <div class="map">
    <!-- 选项框 -->
    <ul class="map-nav">
      <!-- 遍历产生五个选项 -->
      <li v-for="i of 6" :key="i" @click="nav_change(i)">
        <!-- !! 动态改变图片 !!!-->
        <img
          :src="require(`@/assets/images/map_nav/${i - 1}.png`)"
          alt="sorry 图裂了"
          v-show="navigation[i - 1]"
        />
        <img
          :src="require(`@/assets/images/map_nav/${i - 1}_out.png`)"
          alt="sorry 图裂了"
          v-show="!navigation[i - 1]"
        />
      </li>
    </ul>
    <!-- 下部 地图内容 -->
    <div>
      <!-- 底部浅蓝色拼图 -->
      <div class="blue">
        <div v-for="i of 7" :key="i" v-show="blue_show[i - 1]">
          <!--!! 动态改变图片 !!!-->
          <img
            :src="require(`@/assets/images/blue/形状${i}.png`)"
            alt="sorry 图裂了"
          />
        </div>
      </div>
      <!-- 标签及旋转图 -->
      <roate-tag
        ref="roate"
        :titleChange="title_change"
        @starIn="starIn"
        @starOut="starOut"
        @navStar="navStar"
      ></roate-tag>
      <!-- 项目列表 -->
      <provice-card-list
        ref="provice"
        :key="proviceKey"
        :proviceCardList="proviceCardList"
        @starIn="starIn"
        @starOut="starOut"
        @tohref="tohref"
        v-if="proviceCardList.length"
      ></provice-card-list>
      <!-- <div class="item-list">
        <div v-for="(item,idx) in proviceCardList" :key="idx">
          <ul>
            <p>{{item.province}}</p>

            <li v-for="(i, index) of item.listData" :key="index">
              <img :src="require(`/public/image/map/列表框/定位标.png`)" alt="sorry 图裂了" />
              {{ i.city }} | {{ i.name }}
            </li>
          </ul>
          <div class="lines">
            <img :src="require(`/public/image/map/列表框/line1.png`)" alt="sorry 图裂了" v-if="idx != 2"/>
            <img :src="require(`/public/image/map/列表框/line2.png`)" alt="sorry 图裂了" v-if="idx == 2"/>
            <div
              style="background-image: url('/image/map/星星符/hover_通用.png')"
              @mouseover="starIn($event, item.province)"
              @mouseout="starOut($event, item.province)"
            ></div>
          </div>
        </div>
      </div> -->

      <!-- 卡片部分 -->
      <div class="cards">
        <div v-show="huanengCard">
          <div class="huaneng-card">
            <p style="padding-top: 22px">华能人才创新基地</p>
            <div style="display: flex; padding: 36px 20px 6px">
              <div>
                <img
                  :src="require(`@/assets/images/card/huaneng01.jpg`)"
                  width="158"
                />
              </div>
              <div style="width: 100%; font-size: 13px">
                <p>
                  <span style="margin-right: 6px">投入时间</span>
                  <span>2020-12-20</span>
                </p>
                <p style="margin-top: 16px">
                  <span style="font-size: 24px">-11</span
                  ><span style="margin-right: 30px">℃</span>
                  <span style="font-size: 24px">14</span>%
                </p>
                <p style="font-size: 12px">
                  <span style="margin-right: 30px">当前温度</span>
                  <span>当前湿度</span>
                </p>
              </div>
            </div>
            <p
              style="
                font-size: 13px;
                padding: 0 8px;
                text-align: left;
                line-height: 18px;
                letter-spacing: 1px;
              "
            >
              华能人才创新创业占地面积约12.7万平方米，规划总建设面积约30万平方米，是华能集团落实国家海外引才战略的落地载体。
            </p>
          </div>
        </div>
        <div v-show="haikouCard">
          <div class="haikou-card">
            <p style="padding-top: 22px">海口喜来登</p>
            <div style="display: flex; padding: 36px 20px 6px">
              <div>
                <img
                  :src="require(`@/assets/images/card/haikou01.jpg`)"
                  width="158"
                />
              </div>
              <div style="width: 100%; font-size: 13px">
                <p>
                  <span style="margin-right: 6px">投入时间</span>
                  <span>2020-12-20</span>
                </p>
                <p style="margin-top: 16px">
                  <span style="font-size: 24px">18</span
                  ><span style="margin-right: 30px">℃</span>
                  <span style="font-size: 24px">85</span>%
                </p>
                <p style="font-size: 12px">
                  <span style="margin-right: 30px">当前温度</span>
                  <span>当前湿度</span>
                </p>
              </div>
            </div>
            <p
              style="
                font-size: 13px;
                padding: 0 8px;
                text-align: left;
                line-height: 18px;
                letter-spacing: 1px;
              "
            >
              对海口喜来登酒店现有能源系统进行一揽子建设及改造
              ，具有示范效应的绿色智能复合型酒店能源综合解决方
              案，安全、高效及稳定的满足酒店各种形式的能源需求。
            </p>
          </div>
        </div>
      </div>
      <!-- 卡片部分 over-->
    </div>
    <!-- 下部 地图内容 over-->
  </div>
  <!--最外层div over-->
</template>

<script>
import { getMapProjectApi, getWeatherDataApi } from '@/api/api'
import ProviceCardList from '../ProviceCardList.vue'
import RoateTag from '../RoateTag.vue'
export default {
  components: {
    ProviceCardList,
    RoateTag
  },
  data() {
    return {
      // 控制选按钮显隐
      // 顺序: 全部 园区 工业 电力 供热 公共建筑
      navigation: [true, false, false, false, false, false],
      //控制标签显示哪一组颜色
      //顺序:鹤岗 大庆 华能 地热 贵港 海口
      title_change: [true, true, true, true, true, true, true],
      // 控制地图浅蓝色显示隐藏
      blue_show: [false, false, false, false, false, false, false],
      // 项目列表
      // neimeng:["呼和浩特 | 上都电厂","巴彦淖尔 | 临河热电","满洲里 | 满洲里电厂","呼伦贝尔 | 东海拉尔电厂","呼伦贝尔 | 扎兰屯热电","呼伦贝尔 | 伊敏电厂"],
      // shanxi:["西安 | 秦岭电厂","西安 | 沣东热力","铜川 | 青岗坪煤矿"],
      // shandong:["威海 | 威海电厂","莱芜 | 莱芜方兴热力","泰安 | 东平沼气","济南 | 黄台电厂","日照 | 日照电厂"],
      neimenggu: [],
      shanxi: [],
      shandong: [],
      // 当前要显示的卡片的内容
      showCard: {
        title: '',
        image: '',
        time: '',
        temperature: '',
        humidity: '',
        text: ''
      },
      // 控制是否显示卡片
      neimengguCardShow: false,
      shanxiCardShow: false,
      shandongCardShow: false,
      // 存放所有列表中卡片内容
      // allCards: [
      //   {
      //     title: "上都电厂",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2020-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "临河热电",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "满洲里电厂",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2020-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "东海拉尔电厂",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2020-12-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "扎兰屯热电",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "伊敏电厂",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "秦岭电厂",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2020-12-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "沣东热力",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "青岗坪煤矿",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "莱芜方兴热力",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2020-12-20",
      //     temperature: "-11",
      //     humidity: "14",
      //     text: "山东莱芜方兴热力公司自管换热站实现对整个供热系统 的实时监测及远程控制，实现二网水力平衡调控，达到 提升供热质量及节能降耗的效果。",
      //   },
      //   {
      //     title: "威海电厂",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "东平沼气",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "黄台电厂",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "日照电厂",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      //   {
      //     title: "曲阜热电",
      //     image: "/image/map/card/laiwu01.jpg",
      //     time: "2021-01-19",
      //     temperature: "",
      //     humidity: "",
      //     text: "",
      //   },
      // ],
      // 控制两张卡片
      huanengCard: false,
      haikouCard: false,
      proviceCardList: [],
      proviceKey: ''
    }
  },
  methods: {
    // 改变当前导航的选中样式
    nav_change(i) {
      if (this.navigation[i - 1] == false) {
        // 样式
        this.navigation = [false, false, false, false, false, false]
        this.navigation.splice(i - 1, 1, true)
        //地图内容切换
        if (i == 1) {
          this.$refs.roate.$refs.titles.forEach((title) => {
            title.className = ''
          })
          this.get_itemData('')
        } else if (i == 2) {
          this.$refs.roate.$refs.titles.forEach((title) => {
            title.className = 'none'
          })
          this.$refs.roate.$refs.titles[2].className = ''
          this.get_itemData('garden')
        } else if (i == 3) {
          this.$refs.roate.$refs.titles.forEach((title) => {
            title.className = 'none'
          })
          this.$refs.roate.$refs.titles[4].className = ''
          this.get_itemData('industry')
        } else if (i == 4) {
          this.$refs.roate.$refs.titles.forEach((title) => {
            title.className = 'none'
          })
          this.$refs.roate.$refs.titles[0].className = ''
          this.$refs.roate.$refs.titles[1].className = ''
          this.get_itemData('power')
        } else if (i == 5) {
          this.$refs.roate.$refs.titles.forEach((title) => {
            title.className = 'none'
          })
          this.$refs.roate.$refs.titles[3].className = ''
          this.get_itemData('supplyHeat')
        } else if (i == 6) {
          this.$refs.roate.$refs.titles.forEach((title) => {
            title.className = 'none'
          })
          this.$refs.roate.$refs.titles[5].className = ''
          this.get_itemData('building')
        }
      }
    },
    // 旋转星部分鼠标移入和移出事件
    starIn(e, i) {
      e.stopPropagation()
      // 显示对应蓝色
      if (i == 1 || i == 2) {
        this.blue_show.splice(0, 1, true)
      } else if (i == 3) {
        this.blue_show.splice(2, 1, true)
        this.huanengCard = true
      } else if (i == 5) {
        this.blue_show.splice(5, 1, true)
      } else if (i == 6) {
        this.blue_show.splice(6, 1, true)
        this.haikouCard = true
      } else if (i == 7) {
        this.blue_show.splice(7, 1, true)
      } else if (i == '内蒙古') {
        this.blue_show.splice(1, 1, true)
      } else if (i == '陕西') {
        this.blue_show.splice(4, 1, true)
      } else if (i == '山东') {
        this.blue_show.splice(3, 1, true)
      }
      // 更改title样式
      this.title_change.splice(i - 1, 1, false)
    },
    starOut(e, i) {
      e.stopPropagation()
      // 隐藏对应蓝色
      if (i == 1 || i == 2) {
        this.blue_show.splice(0, 1, false)
      } else if (i == 3) {
        this.blue_show.splice(2, 1, false)
        this.huanengCard = false
      } else if (i == 5) {
        this.blue_show.splice(5, 1, false)
      } else if (i == 6) {
        this.blue_show.splice(6, 1, false)
        this.haikouCard = false
      } else if (i == 7) {
        this.blue_show.splice(7, 1, false)
      } else if (i == '内蒙古') {
        this.blue_show.splice(1, 1, false)
      } else if (i == '陕西') {
        this.blue_show.splice(4, 1, false)
      } else if (i == '山东') {
        this.blue_show.splice(3, 1, false)
      }
      // 更改title样式
      this.title_change.splice(i - 1, 1, true)
    },
    // 获取项目列表的对应数据
    get_itemData(projectType) {
      // method.getMethod("/hnReadDate/visual/getMapProject",{
      //   projectType:projectType,
      //   tenantId:"",
      // }).then((res)=>{
      //   console.log(res);
      //   console.log("aaaaaa");
      // })

      // 每切换一次 首先让所有项目列表都显示
      let lists = document.querySelectorAll('.item-list>div')
      for (let list of lists) {
        list.style.display = 'flex'
      }

      let defaultParams = {
        sysInsCode: 'HN_f23e0f8d71e8473894df15fb54811c36',
        projectType: projectType,
        tenantId: ''
      }
      getMapProjectApi(defaultParams)
        .then((res) => {
          // 取出获得的数据 只要内蒙 山东 陕西部分
          let arr = res.result
          // 获取数据之前 要先把原本的内容清空
          this.neimenggu = []
          this.shanxi = []
          this.shandong = []
          this.proviceCardList = []
          for (let obj of arr) {
            if (obj.province == '内蒙古') {
              this.neimenggu = obj.listData
              this.proviceCardList.push(obj)
            } else if (obj.province == '陕西') {
              this.shanxi = obj.listData
              this.proviceCardList.push(obj)
            } else if (obj.province == '山东') {
              this.shandong = obj.listData
              this.proviceCardList.push(obj)
            }
          }
          this.proviceCardList.sort((star, next) => {
            var order = ['内蒙古', '陕西', '山东']
            return order.indexOf(star.province) - order.indexOf(next.province)
          })
          this.proviceKey = new Date().getTime() + 'K'
          this.$forceUpdate()

          // 获取数据并放入后 重新绑定移入事件
          // this.addListener_card();
          // 如果获取结束后 存放数据变量的仍为空 那么整个列表隐藏
          if (!this.neimenggu.length) {
            let div1 = document.querySelector('.item-list>div:nth-child(1)')
            div1.style.display = 'none'
          }
          if (!this.shanxi.length) {
            let div2 = document.querySelector('.item-list>div:nth-child(2)')
            div2.style.display = 'none'
          }
          if (!this.shandong.length) {
            let div3 = document.querySelector('.item-list>div:nth-child(3)')
            div3.style.display = 'none'
          }
        })
        .catch((e) => {
          console.log(e.message)
        })
    },
    // 项目列表移入移出效果
    ulIn(province) {
      //  e.stopPropagation()
      if (province == 'neimenggu') {
        this.blue_show.splice(1, 1, true)
      } else if (province == 'shanxi') {
        this.blue_show.splice(4, 1, true)
      } else if (province == 'shandong') {
        this.blue_show.splice(3, 1, true)
      }
    },
    ulOut(province) {
      //  e.stopPropagation();
      if (province == 'neimenggu') {
        this.blue_show.splice(1, 1, false)
      } else if (province == 'shanxi') {
        this.blue_show.splice(4, 1, false)
      } else if (province == 'shandong') {
        this.blue_show.splice(3, 1, false)
      }
    },

    // 给li添加关于卡片的事件监听
    // addListener_card() {
    //   let This = this;
    //   // 山东部分
    //   setTimeout(() => {
    //     let lis_shandong = document.querySelectorAll(
    //       ".item-list>div:nth-child(3) li"
    //     );
    //     for (let count = 0; count < lis_shandong.length; count++) {
    //       lis_shandong[count].addEventListener(
    //         "mouseover",
    //         function () {
    //           This.cardIn("shandong", count);
    //         },
    //         false
    //       );
    //       lis_shandong[count].addEventListener(
    //         "mouseout",
    //         function () {
    //           This.cardOut("shandong", count);
    //         },
    //         false
    //       );
    //     }
    //   }, 500);
    // },
    // 卡片显示隐藏
    // cardIn(province, i) {
    //   // e.stopPropagation();
    //   if (province == "shandong") {
    //     // 获取山东卡片容器
    //     let cardNeimenggu = document.querySelector(".shandong-card");
    //     // 给容器内添加内容
    //     // 获取当前行的数据放入容器
    //     for (let obj of this.allCards) {
    //       if (obj.title == this.shandong[i].name) {
    //         // 显示卡片(要写在里面 有匹配的才显示卡片)
    //         //  this.shandongCardShow=true;
    //         // 获取时间和温度湿度数据更新入obj
    //         // 时间
    //         obj.time = this.shandong[i].operationTime;
    //         // 温度湿度
    //         let defaultParams = {
    //           type: "HnWeatherData",
    //           sysInsCode: this.shandong[i].projectCode,
    //         };
    //         getWeatherDataApi(defaultParams)
    //           .then((res) => {
    //             if (res.data.status == "SUCCESS") {
    //               obj.temperature = res.data.result.data.condition.temp;
    //               obj.humidity = res.data.result.data.condition.humidity;
    //               // console.log(obj);
    //             }
    //             // 将当前行信息替换入容器
    //             this.showCard = obj;

    //             if (obj.title == "威海电厂" || obj.title == "黄台电厂") {
    //               // 根据项目类型 改变容器背景
    //               cardNeimenggu.style.backgroundImage = `url("/image/map/card/电力.png")`;
    //             } else {
    //               cardNeimenggu.style.backgroundImage = `url("/image/map/card/供热.png")`;
    //             }
    //           })
    //           .catch((e) => {
    //             console.log(e.message);
    //           });
    //       }
    //     }
    //   }
    // },
    // cardOut(province, i) {
    //   // e.stopPropagation();
    //   if (province == "shandong") {
    //     // 隐藏卡片
    //     this.shandongCardShow = false;
    //   } else if (province == "neimenggu") {
    //     // 隐藏卡片
    //     this.neimengguCardShow = false;
    //   } else if (province == "shanxi") {
    //     // 隐藏卡片
    //     this.shanxiCardShow = false;
    //   }
    // },
    // 点击跳转
    navStar(ip) {
      if (ip == 3) {
        this.tohref(`bqj.hyhncloud.cn/`)
      } else if (ip == 6) {
        this.tohref(`xld.hyhncloud.cn:8810`)
      } else if (ip == 7) {
        this.tohref(`sxdl.hyhncloud.cn:8810`)
      }
    },
    navS(ip) {
      if (ip == '莱芜方兴热力') {
        this.tohref(`lw.hyhncloud.cn:8820`)
      } else if (ip == '重汽集团') {
        this.tohref(`sdzq.localhost`)
      }
    },
    navT(ip) {
      this.tohref(`xld.hyhncloud.cn:8810`)
    },
    tohref(url) {
      //点击后触发跳转事件，如果是嵌入页面，通知父页面，如果不是嵌入的，则刷新href
      try {
        let project = { port: url, type: 'router' }
        let href = window.parent.href
        if (!href) {
          window.location.href = `http://${url}`
        } else {
          if (url.startsWith('lw')) {
            project.projectId = 3
          }
          window.parent.postMessage(project, '*')
        }
      } catch (error) {
        let project = {
          port: url,
          type: 'router'
        }
        if (url.startsWith('lw')) {
          project.projectId = 3
        }
        window.parent.postMessage(project, '*')
      }
    }
  },
  mounted() {
    // 初始情况下首先发送请求 获取当前页面的项目及项目列表
    this.get_itemData('')
    // 为防止同一事件在li上的覆盖 采用添加事件监听方式给项目列表添加移入移出事件
    // false 表示禁用事件冒泡 采用事件捕获
    // 给ul添加事件监听
    let divs = document.querySelectorAll('.item-list>div')
    let This = this
    let location = ['neimenggu', 'shanxi', 'shandong']
    // 添加事件监听 并传参
    for (let count = 0; count < divs.length; count++) {
      divs[count].addEventListener(
        'mouseover',
        function () {
          This.ulIn(location[count])
        },
        false
      )
    }
    for (let count = 0; count < divs.length; count++) {
      divs[count].addEventListener(
        'mouseout',
        function () {
          This.ulOut(location[count])
        },
        false
      )
    }
  }
}
</script>
<style scoped lang="less">
.map {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* 定高 */
  height: 39.6rem;
  /* border:1px solid rgb(253, 253, 249); */
}
.map-nav {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  list-style: none;
  /* border:1px solid rgb(51, 211, 11); */
  display: flex;
  padding: 1.42rem 5.5rem 0rem;
}
.map-nav > li {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(200, 21, 206); */
  margin-right: 1.8rem;
}
.map-nav > li:hover {
  cursor: pointer;
}
.map > div {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(200, 21, 206); */
  /* 相对定位 */
  position: relative;
  /* 定高 */
  height: 36.7rem;
}
/* 浅蓝色区域 */
.blue > div {
  /* 绝对定位 */
  position: absolute;
  /* border: 1px solid rgb(238, 232, 232); */
}
.blue > div:nth-child(1) {
  left: 50.585rem;
  top: 1.2rem;
}
.blue > div:nth-child(2) {
  left: 28.45rem;
  top: 1.4rem;
}
.blue > div:nth-child(3) {
  left: 46.215rem;
  top: 12.3rem;
}
.blue > div:nth-child(4) {
  left: 45.9rem;
  top: 15rem;
}
.blue > div:nth-child(5) {
  left: 36.4rem;
  top: 14.1rem;
}
.blue > div:nth-child(6) {
  left: 34.25rem;
  top: 24.5rem;
}
.blue > div:nth-child(7) {
  left: 39.5rem;
  bottom: 1.55rem;
}

/* 卡片部分 */
.cards > div {
  /* 绝对定位 */
  position: absolute;
  .huaneng-card {
    width: 378px;
    height: 247px;
    color: #fff;
    background-image: url('~@/assets/images/card/huaneng.png');
  }
  .haikou-card {
    width: 378px;
    height: 247px;
    color: #fff;
    background-image: url('~@/assets/images/card/haikou.png');
  }
}
.cards > div:nth-child(1) {
  top: 6rem;
  left: 18rem;
}
.cards > div:nth-child(2) {
  top: 14.5rem;
  left: 28rem;
}
/* .cards>div:nth-child(3){
    top:8.7rem;
    right:15rem;
  } */
.neimenggu-card,
.shanxi-card,
.shandong-card {
  /* 定宽定高 */
  width: 378px;
  height: 247px;
  color: #fff;
  background: url('/image/map/card/供热.png');
}
.neimenggu-card {
  top: 3rem;
  left: 36rem;
}
.shanxi-card {
  bottom: 3rem;
  left: 0.2rem;
}
.shandong-card {
  top: 8.7rem;
  right: 15rem;
}
</style>
