<template>
  <div class="all" ref="all">
    <div class="up">
      <Htitle />
    </div>
    <!-- <div class="down-earth" v-if="show"> -->
      <!-- <img :src="require(`/public/image/start.gif`)" alt="sorry 图裂了" /> -->
    <!-- </div> -->
    <div class="down" v-if="!show">
      <div class="left">
        <div>
          <today-used></today-used>
        </div>
        <div>
          <evaluate-indicator></evaluate-indicator>
        </div>
        <div>
          <physical-monitoring></physical-monitoring>
        </div>
      </div>

      <div class="center">
        <div>
          <statistic-data></statistic-data>
        </div>
        <div>
          <Map />
        </div>
        <div>
          <bottom-table-data></bottom-table-data>
        </div>
      </div>

      <div class="right">
        <div>
          <comprehensive-energy></comprehensive-energy>
        </div>
        <div>
          <project-data></project-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入标题子组件
import Htitle from '../components/Home/Title.vue'
// 左一
import TodayUsed from '../components/Home/TodayUsed.vue'
// 左中
import EvaluateIndicator from '../components/Home/EvaluateIndicator.vue'
//左下
import PhysicalMonitoring from '../components/Home/PhysicalMonitoring.vue'
//中上
import StatisticData from '../components/Home/StatisticData'
//中间地图
import Map from '../components/Home/Map'
//通知表单
import BottomTableData from '../components/Home/BottomTableData'
//右侧中和能源
import ComprehensiveEnergy from '../components/Home/ComprehensiveEnergy'
//通知表单
import ProjectData from '../components/Home/ProjectData'

export default {
  components: {
    // 配置为子组件
    Htitle,
    TodayUsed,
    EvaluateIndicator,
    PhysicalMonitoring,
    StatisticData,
    Map,
    BottomTableData,
    ComprehensiveEnergy,
    ProjectData,
  },
  data() {
    return {
      // 控制首页动图显示
      show: false
    }
  },
  mounted() {
    //修改当前页面title
    document.title = '华云慧能监测平台'

    //如果按下F11 高度大于1080(取到的是数值型) 则修改最外层div的高为充满屏幕
    window.onkeyup = (e) => {
      // console.log(window.innerHeight);//1080
      // console.log(window.innerWidth);//1366  //1920
      //console.log(this.$refs.all.offsetHeight);
      if (
        e.keyCode == 122 &&
        window.innerHeight > this.$refs.all.offsetHeight
      ) {
        this.$refs.all.style.height = `${window.innerHeight}px`
      }
    }

    // 修改首页动图显示
    setTimeout(() => {
      this.show = false
    }, 6000)
  }
}
</script>

<style scoped>
.all {
  /* 全屏高度为高1080px  宽100% 1100*/
  /* 设置全局高度 app.vue高度由此撑开 */
  height: 1080px;
  /* 若实际全屏后更宽 则内容居中显示 */
  width: 100%;
  margin: 0 auto;
  /* 添加全局背景(app width只是自动为当前视口100%) */
  background-image: url('~@/assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #050D16;
}
/* 1.布局 */
/* 布局中上下采用百分比 防止页面长高后变形 内层均采用定高 */
.up {
  height: 7.3%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /*border: 1px solid #fff;*/
}
.down {
  height: 92.7%;
  display: flex;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /*border:2px solid pink*/
}
/* 地球动图 */
.down-earth {
  height: 93%;
  overflow: hidden;
  text-align: center;
}
.left,
.right {
  flex-basis: 22%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.left {
  padding-left: 1.25rem;
}
.center {
  flex-basis: 56%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /*border: 1px solid rgb(245, 10, 186);*/
  padding-bottom: 1.78rem;
}
/* 左侧 */
.left > div:first-child {
  flex-basis: 24.2%;
  /* border: 1px solid rgb(241, 20, 241); */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.left > div:nth-child(2) {
  flex-basis: 45.9%;
  /*border: 1px solid #fff;*/
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.left > div:nth-child(3) {
  flex-basis: 29.9%;
  /*border: 1px solid rgb(231, 178, 2);*/
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
/* 中间 */
.center > div:first-child {
  flex-basis: 11.6%;
  /*border: 1px solid rgb(8, 230, 27);*/
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.center > div:nth-child(2) {
  flex-basis: 65.9%;
  /*border: 1px solid rgb(238, 13, 208);*/
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.center > div:nth-child(3) {
  flex-basis: 22.5%;
  /*border: 1px solid rgb(245, 243, 239);*/
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
/* 右侧 */
.right > div:first-child {
  flex-basis: 70.3%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /*border:2px solid rgb(248, 204, 6)*/
}
.right > div:nth-child(2) {
  flex-basis: 29.7%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /*border: 1px solid rgb(233, 11, 222);*/
}
/* 2.内容 */
</style>
