<template>
  <div class="structure-container">
    <el-row type="flex" class="row-bg" justify="space-around" style="margin: 20px 0">
      <el-col :span="2"></el-col>
      <!-- <el-col :span="4"><div class="grid-content">平均值</div></el-col> -->
      <el-col :span="8"><div class="grid-content">负荷：<span style="font-size:14px;color: #178CFF">39.4 </span>MW</div></el-col>
      <el-col :span="8"><div class="grid-content">负载率：<span style="font-size:14px;color: #178CFF">52.35 </span>%</div></el-col>
      <el-col :span="2"></el-col>
    </el-row>
    <div>
      <!-- echarts -->
      <div
          style="width:100%;height: 260px; margin-top: -40px"
          :id="echarts"
          class="echarts"
          ref="echarts"
      ></div>
    </div>
  </div>
</template>

<script>
// 引入echarts
import echarts from 'echarts'
export default {
  computed: {
    echarts() {
      return 'echarts' + Math.random()*100000
    },
  },
  methods: {
    initData(data1,data2, data2One){
      this.dataX = ''
      this.dataY = data2
      this.dataY = data2One
      const vm = this;
      vm.$nextTick(() => {
        vm.drawChart();
      });
    },
    // 使用echarts.init这个方法来创建一个 ECharts 实例，返回 echartinstance，不能在单个容器上初始化多个 ECharts 实例,因此需要用到Vue的computed属性来解决这个问题
    // 因为把它封装成了一个Vue组件,因此要注意父子组件之间的通信、需要用到props这个属性
    // 在Vue的生命周期mounted执行，并且在this.$nextTick里面执行这个方法，保证承若所有的子组件被挂载、能保证通过获取到组件
    drawChart () {
      const vm = this
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById(this.echarts))
      // 绘制图表
      myChart.setOption({
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                type: 'dashed ',
                color: '#999'
              }
            },
            data: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
          }
        ],
        yAxis: [
          {
            name: '%',
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                type: 'dashed ',
                color: '#999'
              }
            },
            splitLine: {
              show: false
            },
          },
        ],
        series: [
          {
            name: '搜索引擎',
            type: 'line',
            symbol: 'circle', // 拐点类型
            smooth: true, // 当为true时，就是光滑的曲线（默认为true）；当为false，就是折线不是曲线的了，那这个设为true，下面的（吃饭）数据中设置smooth为false，这个就不是光滑的曲线了。
            symbolSize: 3, // 拐点圆的大小
            // areaStyle: {
            //   normal: {
            //     color: '#095252' //改变区域颜色
            //   }
            // },
            itemStyle : {
              normal : {
                color: '#00F0FF', //改变折线点的颜色
                lineStyle: {
                  color: '#00F0FF' //改变折线颜色
                }
              }
            },
            data: ["51.95", "59.28", "60.85", "49.23", "53.00", "59.21", "59.41", "60.43", "44.94", "49.08", "59.98", "53.95", "50.93", "45.97", "57.49", "44.65", "50.90", "51.41", "58.62", "55.57", "53.25", "46.16", "56.72", "56.89"]
          }
        ]
      }, true)
      // 解决echarts屏幕自适应问题，
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
        // window.onresize = function () {
        //   myChart.resize();
        // }
        // 可以同时生效
        window.addEventListener('resize', ()=> {
          myChart.resize();
        })
      },200)

    }
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
};
</script>

<style lang="less" scoped>
.structure-container {
  height: 276px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .title {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    margin-left: 20px;
  }
  .structure-chart {
    flex: 1;
  }
  .structure-info {
    height: 60px;
    p {
      height: 20px;
      line-height: 25px;
      font-size: 12px;
      color: #ffffff;
    }
    .legend-block {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .square {
    display: inline-block;
    width: 10px;
    height: 10px;
  }
  .color-one {
    background: #3dc7ef;
  }
  .color-two {
    background: #dcef3d;
  }
  .color-three {
    background: #ef3d3d;
  }
  .color-four {
    background: #3def88;
  }
  .legend-font {
    font-size: 10px;
    margin-left: 8px;
    color: #ffffff;
  }
  .mal20 {
    margin-left: 20px;
  }
}
.grid-content{
  font-size: 14px;
  color: #ccc;
}
</style>
