<template>
  <!-- 标签及旋转图 -->
  <div class="title">
    <div v-for="i of 7" :key="i" ref="titles">
      <p>
        <img
          :src="require(`@/assets/images/map/默认/${i}_title.png`)"
          alt="sorry 图裂了"
          v-show="titleChange[i - 1]"
        />
        <img
          :src="require(`@/assets/images/map/选中/${i}_title.png`)"
          alt="sorry 图裂了"
          v-show="!titleChange[i - 1]"
        />
      </p>
      <!-- 遍历产生旋转图div -->
      <div
        class="sprite"
        ref="rotate"
        @mouseover="starIn($event, i)"
        @mouseout="starOut($event, i)"
        @click="navStar(i)"
      >
        <!-- !!动态图片 !!-->
        <div class="sprite_img" :class="genSpriteClass(i)"></div>
      </div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titleChange: {
      type: Array,
      default() {
        return [true, true, true, true, true, true]
      }
    }
  },
  methods: {
    starIn($event, province) {
      this.$emit('starIn', $event, province)
    },
    starOut($event, province) {
      this.$emit('starOut', $event, province)
    },
    navStar(i) {
      this.$emit('navStar', i)
    },
    genSpriteClass(i) {
      return `sprite-img-${i}`
    }
  }
}
</script>
<style scoped>
/* 标签和旋转 */
.title > div {
  /* 绝对定位 */
  position: absolute;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(209, 231, 7); */
  /* 定高 宽度撑开 */
  height: 5rem;
}
.none {
  display: none;
}
.title > div:nth-child(1) {
  right: 4.5rem;
  top: 2.2rem;
}
.title > div:nth-child(2) {
  right: 9rem;
  top: 3.95rem;
}
.title > div:nth-child(3) {
  right: 14.9rem;
  top: 9.5rem;
}
.title > div:nth-child(3) > .sprite {
  /* border: 1px solid #fff; */
  margin-left: 3.2rem;
}
.title > div:nth-child(4) {
  right: 18rem;
  top: 16.5rem;
}
.title > div:nth-child(4) > .sprite {
  /* border: 1px solid #fff; */
  margin-top: -5rem;
  margin-left: 2.3rem;
}
.title > div:nth-child(5) {
  left: 35rem;
  top: 23rem;
}
.title > div:nth-child(6) {
  left: 37.3rem;
  top: 30.2rem;
}
.title > div:nth-child(7) {
  left: 38rem;
  top: 12rem;
}
.sprite {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid #fff; */
  /* 定宽定高 */
  height: 3.1rem;
  width: 4.3rem;
  margin: 0 auto;
  overflow: hidden;
  margin-top: -0.3rem;
  margin-left: 1.6rem;
}
/* 飞镖动画部分 */
@keyframes play {
  from {
    background-position: 0px;
  }
  to {
    background-position: -2430px;
  }
}
.sprite_img {
  width: 54px;
  height: 54px;
  /* border: 1px solid rgb(98, 221, 16);  */
  background-repeat: no-repeat;
  animation: play 3s steps(45) infinite;
}
.sprite-img-1 {
  background-image: url('~@/assets/images/星星符/hover_1.png');
}
.sprite-img-2 {
  background-image: url('~@/assets/images/星星符/hover_2.png');
}
.sprite-img-3 {
  background-image: url('~@/assets/images/星星符/hover_3.png');
}
.sprite-img-4 {
  background-image: url('~@/assets/images/星星符/hover_4.png');
}
.sprite-img-5 {
  background-image: url('~@/assets/images/星星符/hover_5.png');
}
.sprite-img-6 {
  background-image: url('~@/assets/images/星星符/hover_6.png');
}
.sprite-img-7 {
  background-image: url('~@/assets/images/星星符/hover_3.png');
}
.title > div:nth-child(1) .sprite_img,
.title > div:nth-child(2) .sprite_img {
  /* border: 1px solid rgb(244, 245, 240);  */
  margin-left: 0.9rem;
}
.title > div:nth-child(3) .sprite_img,
.title > div:nth-child(6) .sprite_img {
  /* border: 1px solid rgb(244, 245, 240);  */
  margin-left: -0.2rem;
}
.title > div:nth-child(7) .sprite_img {
  margin-left: 1rem;
}
.title > div:nth-child(4) .sprite_img {
  margin-left: 0.75rem;
}
.title > div:nth-child(5) .sprite_img {
  margin-left: 1.35rem;
}
.sprite :hover {
  animation-play-state: paused;
  cursor: pointer;
  opacity: 0.8;
}
</style>