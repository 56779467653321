<template>
  <div class="right-s">
    <p>项目分布统计</p>
    <div class="r-bottom">
      <div>
        <echarts-right-bottom></echarts-right-bottom>
      </div>
    </div>
  </div>
</template>
<script>
import EchartsRightBottom from '@/components/echarts/echartsRightBottom/ProjectDistribute'
export default {
  components: {
    EchartsRightBottom
  }
}
</script>
<style scoped lang="less">
.right-s {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-image: url('~@/assets/images/right/项目统计底.png');
  background-repeat: no-repeat;
  height: 16.6rem;
  & > p {
    color: #fff;
  }
  .r-bottom {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin-top: 2rem;
    & > div {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      width: 21.75rem;
      height: 11.5rem;
      margin: 0 auto;
      display: flex;
      font-size: 0.75rem;
      color: #fff;
    }
  }
}
</style>
