<template>
<div class="box">
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>
</div>

</template>
<script>
import echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    AData: {
      type: Array,
      default: () => []
    },
    BData: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    },
  },
  methods: {
    drawChart() {
      let total = this.BData.reduce((a, b) => {
          return a + b.value * 1
      }, 0);
      let formatNumber = function(num) {
          let reg = /(?=(\B)(\d{3})+$)/g;
          return num.toString().replace(reg, ',');
      }
      // let title = '总数';
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        tooltip:{
        enterable:true
        },
        title: [{
            text: '{val|' + formatNumber(total) + '}',
            top: '60',
            left: 'center',
            textStyle: {
              color: '#ccc',
              fontWeight: 'normal',
              fontSize: 18,
                rich: {
                    name: {
                        fontSize: 34,
                        fontWeight: 'normal',
                        color: '#ccc',
                        padding: [10, 0]
                    },
                    val: {
                        fontSize: 22,
                        fontWeight: 'normal',
                        color: '#fff',
                    }
                }
            }
        }],
        grid:{
            x:0,
            y:0,
            x2:0,
            y2:0,
        },
        legend: {
          bottom: 90,
          width: 340,
          left: 'center',
          itemGap: 10,
          itemWidth: 16,
          textStyle: {
                color: "#fff",
                // fontSize:12,
                align: "left",
                // padding: [0, 8, 0, 0]
              },
          data: this.BData
        },
        series: [
          {
            color: [ "#F0F3BD","#BDF3BD","#02DFB0","#00BBA7","#0295A8","#067FAF"],
            silent: true,
            name: "访问来源",
            type: "pie",
            radius: ["28%", "31%"],
            center: ["50%", "28%"],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
                show: false,
            },
            emphasis: {
              label: {
                show: false, //让中间动态文字不显示
              },
            },
            data: this.AData,
          },
          {
            color:[ "#FD49A2","#FD49DD","#EE49FD","#B747EB","#8C47EB","#479AEB","#55F5D6","#1BF2C8","#30E9A1"],
            silent: true,
            name: "",
            type: "pie",
            radius: ["32%", "40%"],
            center: ["50%", "28%"],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
                padding: [0, 0, 0, 0],
                show: true,
                position: 'outside',
                formatter: [
                    '{name|{b}}',//引导线上面文字
                    // '{text|{c}}','{text|{d}}{percent|%}', //引导线下面文字
                    '{text|{c}}', //引导线下面文字
                ].join("   "), //用\n来换行
                rich: {
                    name: {
                        fontSize: 12,
                        color:'#ccc',
                        padding: [0, 0],
                    },
                    text: {
                        fontSize: 12,
                        color:'#fff',
                    },
                    percent:{
                        fontSize: 12,
                        padding: [0, 0],
                        color:'#ccc'
                    }
                }
            },
            labelLine: {
                normal: {
                    length: 14,
                    length2: 24,
                    align: 'right',
                    lineStyle: {
                        width: 1,
                    }
                }
            },
            emphasis: {
              label: {
                show: false, //让中间动态文字不显示
              },
            },
            data: this.BData,
          },
        ],
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
  mounted() {
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
};
</script>
<style scoped>
.echarts{
  width: 100%;
  height: 100%;
}
.box{
  width: 100%;
  height: 100%;
  color:rgba(rgb(169, 211, 245), .3);
}
</style>
