<template>
  <div class="right-f">
    <p>综合能源</p>
    <div class="elevatorDetection">
      <el-tabs v-model="activeName" @tab-click="handleClick" ref="navTabs">
        <el-tab-pane label="供热" name="first1">
          <div>
            <div class="right-s">
              <p
                v-for="(i, index) of locations1"
                :key="index"
                :class="index === active1 ? 'location' : ''"
              >
                <img :src="require('@/assets/dingwei.png')" alt="">
                {{ i.projectName }}
              </p>
            </div>
            <left-first
              :item="item"
              :inseriesData="inseriesData"
              :xAxisData="xAxisData"
              :key="term"
              v-if="xAxisData.length > 0"
            ></left-first>
          </div>
        </el-tab-pane>
        <el-tab-pane label="电力" name="first2">
          <div>
            <div class="right-s">
              <p
                v-for="(i, index) of locations2"
                :key="index"
                :class="index === active2 ? 'location' : ''"
              >
                <img :src="require('@/assets/dingwei.png')" alt="">
                {{ i.projectName }}
              </p>
            </div>
            <left-second
              :item="item"
              :inseriesData="inseriesData"
              :xAxisData="xAxisData"
              :key="term"
              v-if="xAxisData.length > 0"
            ></left-second>
          </div>
        </el-tab-pane>
        <el-tab-pane label="公共建筑" name="first3">
          <div>
            <div class="right-s">
              <p 
                v-for="(i, index) of locations3" 
                :key="index" 
                :class="index === 1 ? 'location' : ''">
                <img :src="require('@/assets/dingwei.png')" alt="">
                {{ i.projectName }}
              </p>
            </div>
          </div>
          <consumption-structure
              v-if="gardenList.links"
              :key="term"
              :gardenList="gardenList"
            ></consumption-structure>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ConsumptionStructure from '@/components/echarts/ConsumptionStructure'
import LeftFirst from '@/components/echarts/leftFirst'
import LeftSecond from '@/components/echarts/leftSecond'
import * as that from '@/utils'
import axios from 'axios'
import { getProjectInfoApi } from '@/api/api'
export default {
  data() {
    return {
      locations1: [],
      locations2: [],
      locations3: [],
      term: 1,
      activeName: 'first1',
      tip: 1,
      active1: 2,
      active2: 5,
      gardenList: {},
      item: {},
      inseriesData: [],
      xAxisData: [],
      setIntver:null,
      setActiveIntver:null
    }
  },
  components: {
    ConsumptionStructure,
    LeftFirst,
    LeftSecond,
  },
  mounted() {
    this.term++
    this.getProjectInfo('power')
    this.getProjectInfo('supplyHeat')
    this.getProjectInfo('garden')
    // 折线图上部信息 接口
    this.getStationCumulant()
    // 折线图 接口
    this.sysRoomTemperature()
    // 桑基图 接口
    this.getEnerstruct()
    this.setIntver = setInterval(() => {
      this.tip === 3 ? (this.tip = 1) : this.tip++
      this.activeName = 'first' + this.tip
      this.term++
    }, 3000)
    // this.setActiveIntver = setInterval(() => {
    //   this.active1 > 5 ? (this.active1 = 0) : this.active1++
    //   this.active2 > 4 ? (this.active2 = 0) : this.active2++
    // }, 2000)
  },
  methods: {
    // 选项卡切换
    handleClick(tab, event) {
      clearInterval(this.setIntver)
      clearInterval(this.setActiveIntver)
      this.term++
    },
    // 供热 接口
    getProjectInfo(val) {
      const params = {
        sysInsCode: 'HN_f23e0f8d71e8473894df15fb54811c36',
        condition: 'projectType',
        projectType: val,
        tenantId: '',//this.$store.state.tenantId
      }

      getProjectInfoApi(params).then((res) => {
        if(val === 'supplyHeat') {
          this.locations1 = res.result
        } else if(val === 'power') {
          this.locations2 = res.result
        } else if(val === 'garden') {
          // this.locations3 = res.result
          this.locations3 = [
            {
              city: "昌平",
              cityCode: "5",
              operationTime: "2020-12-01",
              projectArea: 0,
              projectCode: "XM_64654da8badc4026a54bedef7ea8b54e",
              projectName: "华能昌平人才创新创业基地综合能源服务项目",
              projectShortName: "华能创新创业基地",
              projectType: "garden",
              province: "北京",
              tenantId: "16"
            },
            {
              city: "昌平",
              cityCode: "5",
              operationTime: "2020-12-01",
              projectArea: 0,
              projectCode: "XM_64654da8badc4026a54bedef7ea8b54e",
              projectName: "海口喜来登酒店",
              projectShortName: "华能创新创业基地",
              projectType: "garden",
              province: "北京",
              tenantId: "16"
            },
            {
              city: "昌平",
              cityCode: "5",
              operationTime: "2020-12-01",
              projectArea: 0,
              projectCode: "XM_64654da8badc4026a54bedef7ea8b54e",
              projectName: "华能山西大楼",
              projectShortName: "华能创新创业基地",
              projectType: "garden",
              province: "北京",
              tenantId: "16"
            }
          ]
        }
      }).catch(e => {
        console.error(e.message)
      })
    },
    getEnerstruct() {
      axios.get('http://xld.hyhncloud.cn/nyksh/visualScreen/getEnerstruct',
        {params: {
          tenantId:'12',
          sysInsCode: 'YQ_9792128ecd6d4d8ca2096c73b74838f2',
          // time:'2021-01',
          time: that.getMonth(new Date()),
          propCode: 'ActivePower,TotalGas'
        }}
      ).then(response => {
          this.gardenList = response.data.result.enerstruct
      }).catch(e => {
        console.error(e.message)
      })
    },
    getStationCumulant() {
      axios.get('http://lw.hyhncloud.cn:8830/lwjk/monitor/getStationCumulant',
        {params: {
          systemInsCode: 'GR_74ea73d1f1244287a97fb26ae5fdbbbc',
          tenantId: 30,
          gatewaycode: 'SiACT20200528V60008'
          }}
        ).then((res) => {
        this.item = res.result
      }).catch(e => {
        console.error(e.message)
      })
    },
    sysRoomTemperature() {
       axios.get('http://lw.hyhncloud.cn:8820/nyksh/heatDistribution/sysRoomTemperature',
        {params: {
            sysInsCode:'GR_74ea73d1f1244287a97fb26ae5fdbbbc',
            sysCode:'HRZ',
            virtual:'nonvirtual',
            tenantId:'30',
            itemName:'RoomTemp',
            time: that.format(new Date()),
          }}
        ).then(response => {
          this.inseriesData = response.data.result.inseriesData
          this.xAxisData = response.data.result.xAxisData
        }).catch(e => {
          console.error(e.message)
        })
    }
  }
}
</script>
<style lang="less" scoped>
.right-f {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-image: url('~@/assets/images/right/综合能源底.png');
  background-repeat: no-repeat;
  height: 42.3rem;
  & > p {
    color: #fff;
  }
  /deep/.el-tabs__nav {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    margin: 1.2rem auto 0;
    width: 360px;
    float: none;
  }
  /deep/ .el-tabs__item {
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 0.875rem;
    height: 1.5rem;
    width: 7.43rem;
    line-height: 1.5rem;
    background-color: #0f4c87;
    border: 1px solid #0f4c87;
    color: #ccc;
    opacity: 0.6;
  }
  /deep/ .el-tabs__nav-wrap::after,
  /deep/.el-tabs__active-bar {
    height: 0;
  }
  /deep/.el-tabs__item.is-active {
    color: #fff !important;
    opacity: 1;
    border: 1px solid #178cff;
  }
  .on {
    color: #fff !important;
    border: 1px solid #0092fe !important;
    opacity: 1 !important;
  }
  .right-s {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 14.6rem;
    padding: 1.2rem 2rem 0rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      height: 0.2rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.625rem;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #0b2c50;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ededed;
    }
    & > p {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      font-size: 0.875rem;
      color: #fff;
      height: 1.5rem;
      line-height: 1.5rem;
      text-align: left;
    }
    & > p:not(:first-child) {
      margin-top: 0.2rem;
    }
    & > p > img {
      margin: 0rem 0.6rem 0rem;
    }
    .location {
      background-image: linear-gradient(
        to right,
        #0e4a84 0%,
        #051829 80%,
        #000 100%
      )
    }
  }
}
</style>
