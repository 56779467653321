<template>
<div class="box">
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>
</div>

</template>
<script>
import echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    AData: {
      type: Array,
      default: () => []
    },
    BData: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    },
  },
  methods: {
    drawChart() {
      // let title = '总数';
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '6%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLabel: {
            rotate: 45,
            show: true,
            textStyle: {
              color: '#B3B6BA'
            },
            interval: 1 //解决数据多，x轴会自动隐藏一部分的效果
          },
          splitLine: {
            show: false
          },
          axisTick: {show: false},
          axisLine: {
            lineStyle: {
              type: 'dashed ',
              color: '#999'
            }
          },
        },
        yAxis: {
          type: 'category',
          data: ['换热器','储能','DTU','智能网关','智能热表','智能电表'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#B3B6BA'
            },
            interval: 0 //解决数据多，x轴会自动隐藏一部分的效果
          },
          splitLine: {
            show: false
          },
          axisTick: {show: false},
          axisLine: {
            lineStyle: {
              type: 'dashed ',
              color: '#999'
            }
          },

        },
        series: [
          {
            name: '',
            type: 'bar',
            data: [300, 600, 900, 1200, 1500, 1800],
            barWidth : 8,
            itemStyle: {
              emphasis: {
              },
              normal: {
                // 设置柱子的颜色
                color: new echarts.graphic.LinearGradient(0,0,1,0,
                    [
                      {offset: 0, color: '#060e13'},                   //柱图渐变色
                      {offset: 0.5, color: '#3192c7'},                 //柱图渐变色
                      {offset: 1, color: '#17A5FF'},                   //柱图渐变色
                    ]
                ),
                barBorderRadius: 5, // 柱子圆角设置
                // 以下为是否显示，显示位置和显示格式的设置了
                label: {
                  show: false,
                  position: 'top',
                  formatter: '{b}\n{c}'
                }
              }
            }
          }
        ]
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
};
</script>
<style scoped>
.echarts{
  width: 100%;
  height: 100%;
}
.box{
  width: 100%;
  height: 100%;
  color:rgba(rgb(169, 211, 245), .3);
  margin-top: -20px;
}
</style>
