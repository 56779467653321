// 获取当前年份
export function getYear() {
  return new Date().getFullYear()
}
export function getMonth(time) {
  var d;
  if (time) {
    d = time
  } else {
    return ''
  }
  var year = d.getFullYear()
  var month = change(d.getMonth() + 1)
  var day = change(d.getDate())
  var hour = change(d.getHours())
  var minute = change(d.getMinutes())
  var second = change(d.getSeconds())
  function change(t) {
    if (t < 10) {
      return '0' + t
    } else {
      return t
    }
  }
  // var time_str = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  var time_str = year + '-' + month
  return time_str
}
// 格式化日期
export function format(time) {
  var d;
  if (time) {
    d = time
  } else {
    return ''
  }
  var year = d.getFullYear()
  var month = change(d.getMonth() + 1)
  var day = change(d.getDate())
  var hour = change(d.getHours())
  var minute = change(d.getMinutes())
  var second = change(d.getSeconds())
  function change(t) {
    if (t < 10) {
      return '0' + t
    } else {
      return t
    }
  }
  // var time_str = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  var time_str = year + '-' + month + '-' + day
  return time_str
}
// 格式化日期(昨天)
export function formatYesterday(time) {
  var d;
  if (time) {
    d = time
  } else {
    return ''
  }
  var year = d.getFullYear()
  var month = change(d.getMonth() + 1)
  var day = change(d.getDate())-1
  var day1 = new Date();
  day1.setTime(day1.getTime()-24*60*60*1000);
  function change(t) {
    if (t < 10) {
      return '0' + t
    } else {
      return t
    }
  }
  // var time_str = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  var time_str = year + '-' + month + '-' + change(day1.getDate())
  return time_str
}

// 数字千分位
export function formatNumber(num) {
  if (isNaN(num)) {
    throw new TypeError("num is not a number");
  }

  return ("" + num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
}

// 获取ip地址
export function getIPAddress() {
  const host = window.location.host
  const splitIndex = host.indexOf(':')
  const ipAddress = host.substring(0, splitIndex > -1 ? splitIndex : host.length)
  return ipAddress
}
