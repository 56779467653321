<template>
  <div class="structure-container">
    <div class="title">用能结构(月度)</div>
    <div class="structure-chart" ref="sankeyChart"></div>
    <div class="structure-info">
      <p>能源品质排序 (高-->低)</p>
      <div class="legend-block">
        <span>
          <i class="square color-one"></i>
          <span class="legend-font">配电</span>
        </span>
        <span class="mal20">
          <i class="square color-two"></i>
          <span class="legend-font">空调</span>
        </span>
        <span class="mal20">
          <i class="square color-three"></i>
          <span class="legend-font">热水</span>
        </span>
        <span class="mal20">
          <i class="square color-four"></i>
          <span class="legend-font">蒸汽</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    gardenList:{
      type:Object,
    }
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove"
        },
        series: {
          type: "sankey",
          top: 0,
          left: 20,
          right: 100,
          bottom: 20,
          nodeWidth: 15,
          data: [],
          links: [],
          nodeGap: 18,
          focusNodeAdjacency: "allEdges",
          label: {
            color: "#fff",
            fontSize: 12,
            lineHeight: 12,
            formatter: params => {
              if (
                params.data.name === "锅炉" ||
                params.data.name === "空调机" ||
                params.data.name === "蒸汽机"
              ) {
                return this.thousandBitSeparator(params.data.value);
              } else {
                return (
                  params.data.name +
                  "\n" +
                  this.thousandBitSeparator(params.data.value)
                );
              }
            }
          },
          layoutIterations: 200,
          itemStyle: {
            borderWidth: 0
          },
          lineStyle: {
            color: "#27F3C8",
            opacity: 0.3
          },
          levels: [{
            depth: 0,
            itemStyle: {
              color: '#27F3C8'
            },
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          }]
        }
      }
    };
  },
  methods: {
    darwChart() {
      const chart = this.$echarts.init(this.$refs.sankeyChart);
      chart.setOption(this.option);
    },
    thousandBitSeparator(num) {
      if (num || num === 0) {
        var c = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() :
            num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
        return c
      }
    }
  },
  mounted() {
    this.option.series.data = [];
    const colorList = [
      {name: '供电',color:'#2affdc'},
      {name: '配电',color:'#3dc7ef'},
      {name: '空调',color:'#dcef3d'},
      {name: '热水',color:'#ef3d3d'},
      {name: '蒸汽',color:'#3def88'}]
    // const colorList = ['#2affdc','#3dc7ef','#3def88','#ef3d3d','#dcef3d']
    this.gardenList.sourceData.forEach((item,index) => {
      const curColor = colorList.find(e => e.name === item.name).color;
      this.option.series.data.push({
        ...item,
        itemStyle: {
          color: curColor,
          borderColor: curColor
        }
      })
    })
    // this.option.series.data = this.gardenList.sourceData;
    this.option.series.links = this.gardenList.links
    this.darwChart()
  },
};
</script>

<style lang="less" scoped>
.structure-container {
  height: 276px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .title {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    margin-left: 20px;
  }
  .structure-chart {
    flex: 1;
  }
  .structure-info {
    height: 60px;
    p {
      height: 20px;
      line-height: 25px;
      font-size: 12px;
      color: #ffffff;
    }
    .legend-block {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .square {
    display: inline-block;
    width: 10px;
    height: 10px;
  }
  .color-one {
    background: #3dc7ef;
  }
  .color-two {
    background: #dcef3d;
  }
  .color-three {
    background: #ef3d3d;
  }
  .color-four {
    background: #3def88;
  }
  .legend-font {
    font-size: 10px;
    margin-left: 8px;
    color: #ffffff;
  }
  .mal20 {
    margin-left: 20px;
  }
}
</style>
