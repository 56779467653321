import Home from '../views/Home.vue'
// import Vue from 'vue'
// import VueRouter from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }
]
// Vue.use(VueRouter)
export default routes
