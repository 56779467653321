<template>
<div class="box">
  <div class="statics-info">
    <p v-if="tabindex === 'first1'">tce/万元</p>
    <p v-if="tabindex === 'first2'">万tce</p>
    <p v-if="tabindex === 'first3'">g/kWh</p>
    <p v-if="tabindex === 'first4'">W/㎡</p>
    <p v-if="tabindex === 'first5'">kgce/㎡</p>
  </div>
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>
</div>
</template>

<script>
export default {
  props: {
    BData: {
      type: Array,
      default: () => []
    },
    outSite: {
      type: Array,
      default: () => []
    },
    tabindex: {
      type: String,
      default:"first1"
    },
  },
  data() {
    return {
      AData:[]
    };
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    },
  },
  methods: {
    drawChart() {
      let total = this.outSite.reduce((a, b) => {
        if(this.tabindex === 'first2') {
          return 7.08
        } else if(this.tabindex === 'first5') {
          return 23.5
        } else {
          return a + b.value * 1
        }
      }, 0);
      let formatNumber = function(num) {
        let reg = /(?=(\B)(\d{3})+$)/g;
        return num.toString().replace(reg, ',');
      }
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        // tooltip: {
        //   trigger: 'item',
        //   formatter: '{b}: {c} ({d}%)'
        // },
        title: [{
          // text:  formatNumber(total) ,
          // top: '50',
          // left: '150',
          textStyle: {
            color: '#ccc',
            fontWeight: 'normal',
            fontSize: 18,
            width: 40,
            align: 'center',
            rich: {
              name: {
                fontSize: 34,
                fontWeight: 'normal',
                color: '#ccc',
                align: 'center',
                padding: [10, 0]
              },
              val: {
                fontSize: 22,
                fontWeight: 'normal',
                color: '#fff',
                align: 'center',
              }
            }
          }
        }],
        tooltip:{show:true,
        position: function(point, params, dom, rect, size) {
            //其中point为当前鼠标的位置，size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
            var x = point[0]; //
            var y = point[1];
            var boxWidth = size.contentSize[0];
            var boxHeight = size.contentSize[1];
            var posX = 0; //x坐标位置
            var posY = 0; //y坐标位置

            if (x < boxWidth) { //左边放不开
                posX = 5;
            } else { //左边放的下
                posX = x - boxWidth;
            }

            if (y < boxHeight) { //上边放不开
                posY = 5;
            } else { //上边放得下
                posY = y - boxHeight;
            }

            return [posX, posY];

        }},
        legend: {
          orient: 'vertical',
          top: 40,
          right: 40,
          data: [],
          textStyle: {
            color: "#ccc",
            fontSize:12,
            align: "left"
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['72%', '80%'],
            center: ["42%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'center',
              formatter:function(){
                return  formatNumber(total)
              },
              textStyle:{
                fontSize:20,
                color:'#eee'
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.outSite,
            color: this.BData,
          },
        ]
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.drawChart();
    })
  },
};
</script>
<style scoped lang="less">
.echarts{
  width: 375px;
  height: 140px;
}
.box{
  width: 375px;
  height: 140px;
  color: #A3D1FF;
  position: relative;
  .statics-info {
    position: absolute;
    top: 74px;
    left: 120px;
    width: 82px;
    overflow: hidden;
    text-align: center;
  }
}
</style>
