<template>
  <!-- 项目列表 -->
  <div class="item-list" v-if="proviceCardList">
    <div v-for="(item, idx) in proviceCardList" :key="idx" :class="[proviceCardList[idx].province == '内蒙古'?'neimenggu':proviceCardList[idx].province == '陕西'?'shanxi':proviceCardList[idx].province == '山东'?'shandong':'']">
      <ul>
        <p>{{ item.province }}</p>
        <!-- 遍历产生列表项 -->
        <li v-for="(i, index) of item.listData" :key="index" @click="gotoProject(i)">
          <img :src="require('@/assets/images/map/列表框/定位标.png')" alt="sorry 图裂了" />
          {{ i.city }} | {{ i.name }}
        </li>
      </ul>
      <!-- 线和旋转标 -->
      <div class="lines">
        <img
          :src="require('@/assets/images/map/列表框/line1.png')"
          alt="sorry 图裂了"
          v-if="idx != 2"
        />
        <img
          :src="require('@/assets/images/map/列表框/line2.png')"
          alt="sorry 图裂了"
          v-if="idx == 2"
        />
        <div
          @mouseover="starIn($event, item.province)"
          @mouseout="starOut($event, item.province)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    proviceCardList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    starIn($event, province) {
      this.$emit("starIn", $event, province);
    },
    starOut($event, province) {
      this.$emit("starOut", $event, province);
    },
    gotoProject(i) {
      if('莱芜' === i.city) {
        this.$emit('tohref', 'lw.hyhncloud.cn:8820')
      }
    }
  },
};
</script>
<style scoped>
/* 项目列表 */
.item-list > div {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(97, 231, 7); */
  display: flex;
}
/* 悬停 */
.item-list > div:hover {
  opacity: 0.85;
}
.item-list ul {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid #fff; */
  list-style: none;
  font-size: 0.875rem;
  color: #fff;
  background-repeat: no-repeat;
  overflow: hidden;
}
.item-list ul > p {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid #fff; */
  /* 定高 */
  height: 1.6rem;
  line-height: 1.6rem;
  padding-top: 0.1rem;
  margin-bottom: 0.2rem;
  font-size: 0.875rem;
  letter-spacing: 0.08rem;
  background-image: url("~@/assets/images/bg-title.png");
}
.item-list ul li {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-align: left;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  padding-left: 0.8rem;
  background-color: rgba(15, 15, 15, 0.6);
  /* border: 1px solid #fff; */
}
.item-list ul li:hover {
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgba(26, 82, 235, 0.3) 0%,
    rgba(29, 74, 221, 0.3) 80%,
    rgba(0, 0, 0, 0.3) 93%,
    rgba(225, 225, 225, 0) 100%
  );
}
.item-list ul li > img {
  margin-right: 0.5rem;
}
.item-list > .neimenggu {
  /* 相对定位 */
  position: absolute;
  left: 16.5rem;
  top: 5rem;
}
.item-list > .neimenggu > ul {
  /* 定高定宽 */
  width: 13.8rem;
  /* height: 10.9rem; */
}
.item-list > .shanxi {
  /* 相对定位 */
  position: absolute;
  left: 24rem;
  top: 16.5rem;
}
.item-list > .shanxi > ul {
  /* 定高定宽 */
  width: 10.7rem;
  /* height: 6.8rem; */
}
.item-list > .shandong {
  /* 相对定位 */
  position: absolute;
  right: 1rem;
  top: 11.5rem;
}
.item-list > .shandong > ul {
  /* 定高定宽 */
  width: 12.2rem;
  /* height: 9.7rem; */
}
/* 项目列表边线 */
.lines {
  /* border: 1px solid #fff; */
  /* 相对定位 */
  position: relative;
}
.lines > img {
  /* 绝对定位 */
  position: absolute;
  /* border: 1px solid #fff; */
}
.lines > div {
  /* border: 1px solid #fff; */
  width: 54px;
  height: 54px;
  background-repeat: no-repeat;
  /* 绝对定位 */
  position: absolute;
  background-image: url("~@/assets/images/星星符/hover_通用.png");
  animation: play 3s steps(45) infinite;
}

/* 内蒙线 */
.item-list > .neimenggu > .lines > img:nth-child(1) {
  top: 7rem;
}
.item-list > .neimenggu > .lines > div {
  top: 5.39rem;
  left: 2.2rem;
}

/* 陕西线 */
.item-list > .shanxi > .lines > img:nth-child(1) {
  top: 2rem;
}
.item-list > .shanxi > .lines > div {
  top: 0.6rem;
  left: 2.2rem;
}
/* 山东线 */
.item-list > .shandong > .lines > img:nth-child(1) {
  top: 4rem;
  right: 12.8rem;
}
.item-list > .shandong > .lines > div {
  top: 2.5rem;
  left: -16.4rem;
}
/* 悬停 */
.lines > div:hover,
.lines > img:hover {
  animation-play-state: paused;
  cursor: pointer;
}

</style>