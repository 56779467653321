import { render, staticRenderFns } from "./RoateTag.vue?vue&type=template&id=54ca7126&scoped=true&"
import script from "./RoateTag.vue?vue&type=script&lang=js&"
export * from "./RoateTag.vue?vue&type=script&lang=js&"
import style0 from "./RoateTag.vue?vue&type=style&index=0&id=54ca7126&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ca7126",
  null
  
)

export default component.exports