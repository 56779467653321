import {get, post } from './request'

export const getTodayEnergyApi = (params) => get('/hnReadDate/visual/getTodayEnergy', params)

export const getProjectInvestmentApi = (params) => get('/hnReadDate/visual/getProjectInvestment', params)

export const getProjectInfoApi = (params) => get('/hnReadDate/visual/getProjectInfo', params)

export const getSysCarbonEmissionsApi = (params) => get('/hnReadDate/visual/getSysCarbonEmissions', params)

// 天气
export const getWeatherDataApi = (params) => get('/hnReadDate/visual/weatherData', params)

//地图
export const getMapProjectApi = (params) => get('/hnReadDate/visual/getMapProject', params)

export const getTargetAppraiseApi = (params) => get('/hnReadDate/visual/getTargetAppraise', params)

export const getConfirmNoticeApi = (params) => post('/hnReadDate/visual/confirmNotice', params)

export const getNoticeApi = (params) => get('/hnReadDate/visual/getNotice', params)

export const getProjectCountByProvinceApi = (params) => get('/hnReadDate/visual/getProjectCountByProvince', params)
