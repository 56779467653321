import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        systemInsCode: 'GR_74ea73d1f1244287a97fb26ae5fdbbbc',
        tenantId: '30',
        gatewaycode: 'SiACT20200528V60008'
    },
    mutations: {},
    actions: {},
    modules: {}
})