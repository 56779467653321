// 微前端
import './public-path'
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router'
import store from './store'
import App from './App.vue'
// 公共样式
import './assets/style/index.less'
// 引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import less from 'less'
Vue.use(less)
Vue.use(ElementUI)
// 引入axios
import axios from 'axios'
axios.defaults.baseURL = ''
Vue.prototype.axios = axios
// 引入Echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import * as filterCommon from '@/utils/filters.js'

Object.keys(filterCommon).forEach((key) => Vue.filter(key, filterCommon[key]))

Vue.config.productionTip = false
let router = null
let instance = null
function render(props = {}) {
  const { container } = props
  router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? '/hyhn-home/#/' : '/',
    mode: 'hash',
    routes
  })
  Vue.use(VueRouter)
  instance = new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped')
}
export async function mount(props) {
  console.log('[vue] props from main framework', props)
  render(props)
}
export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  router = null
}
