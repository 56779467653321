<template>
  <div class="left-s">
    <p>指标评价</p>
    <p>
      综合能耗
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
      >
        <!-- <el-tab-pane label="园区" name="first1">
          <div style="position: relative">
            <energy-consum-chart
              :BData="['#00F0FF']"
              :outSite="garden"
              v-if="trem"
              :tabindex="'first1'"
            ></energy-consum-chart>
            <div class="progress_title">
              <span>能耗</span>
              <p class="energy-title">
                单位能耗排名
              </p>
            </div>
          </div>
        </el-tab-pane> -->
         <!-- disabled="disabled" -->
        <el-tab-pane label="工业" name="first2">
          <energy-consum-chart
            :BData="['#00F0FF']"
            :outSite="industry"
            v-if="trem"
            :tabindex="'first2'"
          ></energy-consum-chart>
          <div class="progress_title">
            <span>能耗</span>
            <p class="energy-title">
              能耗排名
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="电力" name="first3">
          <energy-consum-chart
            :BData="['#00F0FF']"
            :outSite="electricity"
            v-if="trem"
            :tabindex="'first3'"
          ></energy-consum-chart>
          <div class="progress_title">
            <span>能耗</span>
            <p class="energy-title">
              度电煤耗排名
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="供热" name="first4">
          <div>
            <energy-consum-chart
              :BData="['#E5FF58', '#E39D23']"
              :outSite="supplyHeat"
              v-if="trem"
              :tabindex="'first4'"
            ></energy-consum-chart>
            <div class="progress_title">
              <span>能耗</span>
              <p class="energy-title">
                单位面积耗热排名
              </p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="公共建筑" name="first5">
          <div>
            <energy-consum-chart
              :BData="['#E39D23']"
              :outSite="building"
              v-if="trem"
              :tabindex="'first5'"
            ></energy-consum-chart>
            <div class="progress_title">
              <span>能耗</span>
              <p class="energy-title">
                单位面积能耗排名
              </p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!--      <span style="margin-left: 14px;color: #999;"><i style="font-size: 16px;color: #fff;margin-right: 4px;">24</i>tce/㎡ · 年</span>-->
    </p>
    <div class="echartsbox1">
      <template>
        <div
          v-for="(item, index) in stopList"
          :key="index"
        >
          <div class="progress-title" v-if="item.value !== 0">
            <span class="label" :title="item.name">
              {{item.name}}
            </span>
            <el-progress
              v-if="index < 3"
              :percentage="(item.value / stopList[0].value) * 100"
              :show-text="false"
              :color="item.color"
            ></el-progress>
            <span>
              {{ item.value }}
              <span class="unit" v-if="trem1 === 1">
                tce/万元
              </span>
              <span class="unit" v-else-if="trem1 === 2">
                万tce
              </span>
              <span class="unit" v-else-if="trem1 === 3">
                g/kWh
              </span>
              <span class="unit" v-else-if="trem1 === 4">
                W/㎡
              </span>
              <span class="unit" v-else-if="trem1 === 5">
                kgce/㎡
              </span>
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EnergyConsumChart from '@/components/echarts/EnergyConsumChart.vue'
import { getTargetAppraiseApi } from '@/api/api'
import * as that from '@/utils'
export default {
  data() {
    return {
      stopList: [],
      stopList1: [],
      outSite: [{ name: '', value: '' }],
      CircleDate7: {
        AData: [
          { name: '物联', value: 33 },
          { name: '数据', value: 32 },
          { name: '应用', value: 37 }
        ],
        LXl: '12.6%',
        NowLxcount: '56次',
        totaltime: '256小时',
        BData: [
          { name: '商业', value: 10 },
          { name: '工业', value: 10 },
          { name: '告警', value: 13 }
        ]
      },
      trem: false,
      trem1: 1,
      activeName: 'first2',
      garden: [],
      supplyHeat: [],
      building: [],
      industry: [],
      electricity: []
    }
  },
  components: {
    EnergyConsumChart
  },
  methods: {
    // 指标评价接口 接口请求
    getTargetAppraise() {
      const params = {
        time: that.formatYesterday(new Date()),
        mediumCodes: 'ActivePower,TotalSteam,TotalHeat,TotalGas,TotalCold',
        projectType: 'garden,supplyHeat,building',
        sysInsCode: 'HN_f23e0f8d71e8473894df15fb54811c36',
        tenantId: '',
        useMeans: 'use'
      }
      getTargetAppraiseApi(params)
        .then((res) => {
          this.building = res.result.building.itemProjectData
          // this.garden = res.result.garden.itemProjectData
          this.garden = [
            {
              name: "桑坡皮毛产业园",
              value: 1
            },
            {
              name: "陕汽工业园",
              value: 3
            },
            {
              name: "吉林袜业园",
              value: 2
            }
          ]
          this.industry = [
            {
              name: "法士特",
              value: 8
            },
            {
              name: "汇丰管业",
              value: 3
            },
            {
              name: "上汽红岩",
              value: 2
            }
          ]
          this.electricity = [
            {
              name: "",
              value: 306
            }
          ]
          // this.supplyHeat = res.result.supplyHeat.itemProjectData
          this.supplyHeat = [
            {
              name: "",
              value: 27.9
            }
          ]
          // this.stopList1 = res.result.building.itemProjectData
          this.stopList1 = [
            {
              name: "海口喜来登酒店",
              value: 48.6
            },
            {
              name: "华能山西大楼",
              value: 23.7
            },
            {
              name: "华能创新创业基地",
              value: 22.6
            }
          ]
          // this.stopList2 = res.result.garden.itemProjectData
          this.stopList2 = [
            {
              name: "桑坡皮毛产业园",
              value: 0.3
            },
            {
              name: "陕汽工业园",
              value: 0.2
            },
            {
              name: "吉林袜业园",
              value: 0.1
            }
          ]
          this.stopList5 = [
            {
              name: "陕西法士特汽车传动",
              value: 7.25
            },
            {
              name: "河南汇丰管业",
              value: 6.89
            },
            {
              name: "重庆上汽红岩",
              value: 6.25
            }
          ]
          this.stopList6 = [
            {
              name: "满洲里电厂",
              value: 139
            },
            {
              name: "东海拉尔电厂",
              value: 134
            },
            {
              name: "扎兰屯热电厂",
              value: 132
            }
          ]
          // this.stopList3 = res.result.supplyHeat.itemProjectData
          this.stopList3 = [
            {
              name: "山东聊城供热",
              value: 40.5
            },
            {
              name: "天津杨柳青供热",
              value: 31.8
            },
            {
              name: "山东莱芜供热",
              value: 27.9
            }
          ]
          this.stopList1.length > 3 ? (this.stopList1.length = 3) : ''
          this.stopList2.length > 3 ? (this.stopList2.length = 3) : ''
          this.stopList3.length > 3 ? (this.stopList3.length = 3) : ''
          this.stopList = this.stopList2
          this.trem = true
        })
        .catch((e) => {
          console.log(e.message)
        })
    },
    handleClick(tab, event) {
      if (tab.label === '园区') {
        this.trem1 = 1
        this.stopList = this.stopList2
      } else if (tab.label === '供热') {
        this.trem1 = 4
        this.stopList = this.stopList3
      } else if (tab.label === '公共建筑') {
        this.trem1 = 5
        this.stopList = this.stopList1
      } else if (tab.label === '工业') {
        this.trem1 = 2
        this.stopList = this.stopList5
      } else if (tab.label === '电力') {
        this.trem1 = 3
        this.stopList = this.stopList6
      }
    }
  },
  mounted() {
    this.getTargetAppraise('garden')
  }
}
</script>

<style lang="less" scoped>
.left-s {
  height: 27.4rem;
  background-image: url("~@/assets/images/bg_target_evaluation.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /deep/ .el-tabs {
    height: 275px;
  }
  & > p {
    color: #fff;
  }
  & > p:nth-child(2) {
    font-size: 0.875rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid #e407c7; */
    margin-top: 0.7rem;
    padding-left: 1.6rem;
    text-align: left;
  }
  & > div {
    width: 20rem;
    height: 18rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin: 0 auto;
  }
  .echartsbox1 {
    height: 6.25rem;
    width: 100%;
    box-sizing: border-box;
    color: #fff;
    font-size: 12px;
    // margin-top: 66px;
    padding: 10px 0;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &>div {
      margin-bottom: 10px;
    }
    .progress-title {
      display: flex;
      .label {
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        margin-left: 40px;
        text-align: left;
      }
      .unit {
        color: #ccc;
        margin-left: 4px;
      }
    }
  }
  /deep/ .el-progress {
    width: 150px;
    margin: 8px;
  }
  /deep/.el-progress-bar__outer {
    background: rgba(23, 140, 255, 0.4);
  }
  /* 选项卡 */
  /deep/.el-tabs__nav {
    /* border: 1px solid rgb(245, 140, 19); */
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    //justify-content: space-around;
    margin: 1.2rem auto 0;
    //width: 360px;
    float: none;
  }
  /deep/ .el-tabs__item {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 0.875rem;
    /* 定高定宽 */
    height: 1.5rem;
    padding: 0 14px !important;
    margin: 0 2px;
    line-height: 1.5rem;
    background-color: #0f4c87;
    border: 1px solid #0f4c87;
    color: #ccc;
    opacity: 0.6;
    text-align: center;
  }
  /deep/ .el-tabs__nav-wrap::after,
  /deep/.el-tabs__active-bar {
    height: 0;
  }
  /deep/.el-tabs__item.is-active {
    color: #fff !important;
    opacity: 1;
    border: 1px solid #178cff;
  }
  /deep/.is-disabled {
    background: rgba(135, 135, 135, 0.6);
    border: none;
    opacity: 1;
    color: #ccc;
  }
  .progress_title {
    padding-top: 12px;
    box-sizing: border-box;
    font-size: 14px;
    height: 80px;
    .energy-title {
      text-align: left;
      color: #fff;
      font-size: 14px;
      margin-bottom: 10px;
    }
    span {
      line-height: 20px;
    }
    p {
      margin-top: 24px;
      line-height: none;
    }
  }
}
</style>
