<template>
  <div class="left-f">
    <p>今日用能</p>
    <div class="six">
      <div v-for="(item, index) of energy" :key="index">
        <div>
          <img :src="require(`@/assets/images/left/logo${index + 1}.png`)" alt="sorr 图裂了" />
        </div>
        <p>{{ item.val }}</p>
        <p>{{ item.unit }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { getTodayEnergyApi } from '@/api/api'
import * as that from '@/utils'
export default {
  data() {
    return {
      energy: [
        { unit: '电力(万kWh)', val: '41363.63', code: 'ActivePower' },
        { unit: '天然气(m³)', val: '3127', code: 'TotalGas' },
        { unit: '热(GJ)', val: '7089', code: 'TotalHeat' },
        { unit: '蒸汽(t)', val: '3246', code: 'TotalSteam' },
        { unit: '冷(GJ)', val: '6727', code: 'TotalCold' },
        { unit: '压缩空气(m³)', val: '3058', code: 'AirPressure' }
      ]
    }
  },
  methods: {
    // 年度、月度用能 接口请求
    getTodayEnergy() {
      const params = {
        useMeans: 'use',
        sysInsCode: 'HN_f23e0f8d71e8473894df15fb54811c36',
        time: that.getYear(new Date()),
        mediumCodes:
          'ActivePower,TotalSteam,TotalHeat,TotalGas,TotalCold,AirPressure',
        tenantId: ''
      }
      getTodayEnergyApi(params).then(res => {
        this.energy.forEach(item => {
          item.val = res.result[item.code]
        })
      }).catch((e) => {
        console.error(e.message)
      })
    }
  },
  mounted() {
    // this.getTodayEnergy()
    // setInterval(() => {
    //   this.getTodayEnergy()
    // }, 30 * 60 * 1000)
  }
}
</script>
<style scoped>
.left-f {
  /*border: 1px solid #3be407;*/
  background-image: url('~@/assets/images/left/今日用能底.png');
  background-repeat: no-repeat;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* 定高 */
  height: 13.8rem;
}
.left-f > p {
  color: #fff;
}
.six {
  /* border: 1px solid #fff; */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 0.875rem;
  margin: 0 auto;
  margin-top: 1rem;
  /* 定宽 */
  width: 95%;
}
.six > div {
  /* border: 1px solid rgb(238, 204, 11); */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  flex-basis: 33.3%;
  /* 定高 */
  height: 5.5rem;
}
.six > div > div {
  /* border:1px solid #ffffff; */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 2.07rem;
  line-height: 2.07rem;
}
.six > div > div > img {
  vertical-align: center;
}
.six > div > p:nth-child(2) {
  color: #fff;
  font-weight: 600;
}
.six > div > p:last-child {
  margin-top: 0.2rem;
  color: #a3d1ff;
}
</style>
