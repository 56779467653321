<template>
  <div class="left-t">
    <p>物联监测</p>
    <div>
      <div class="left-three">
        <div>
          <p style="color: #05bfbf">
            {{ all }}
          </p>
          <p>设备总数(台)</p>
        </div>
        <div>
          <p style="color: #58c2ff">
            {{ online }}
          </p>
          <p>在线率(%)</p>
        </div>
        <div>
          <p style="color: #c49e36">
            {{ offline }}
          </p>
          <p>离线率(%)</p>
        </div>
      </div>
      <div>
        <echarts-let-bottom :key="trem"></echarts-let-bottom>
      </div>
    </div>
  </div>
</template>

<script>
import EchartsLetBottom from '@/components/echarts/echartsLetBottom'
export default {
  data() {
    return {
      all: 8325,
      online: 99.75,
      offline: 0.25,
      trem: 1
    }
  },
  components: {
    EchartsLetBottom
  },
  mounted() {
    setInterval(() => {
      this.trem++
    }, 8 * 1000)
  }
}
</script>
<style scoped>
.left-t {
  /*border: 1px solid #3be407;*/
  background-image: url('~@/assets/images/left/物联监测底.png');
  background-repeat: no-repeat;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* 定高 */
  height: 16.7rem;
}
.left-t > p {
  color: #fff;
}

/* 内容外框 */
.left-t > div {
  /* border: 1px solid rgb(206, 28, 212); */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-left: 0.9rem;
  /* 定高 */
  height: 14.1rem;
  margin-top: 1.2rem;
  display: flex;
  justify-content: space-between;
}
/* 左侧 */
.left-three {
  /* border:1px solid #fff; */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* 定宽 */
  width: 24.5%;
  padding-top: 0.9rem;
}
.left-three > div {
  /* 定高定宽 */
  width: 100%;
  height: 4rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(15, 194, 9); */
  background-image: url('~@/assets/images/left/物联内部底.png');
  background-repeat: no-repeat;
  padding-top: 0.5rem;
  padding-left: 0.68rem;
}
.left-three > div > p:first-child {
  font-weight: 600;
  font-size: 1.25rem;
  text-align: left;
}
.left-three > div > p:last-child {
  color: #fff;
  text-align: left;
  font-size: 0.75rem;
}
/* 右侧 */
.left-t > div > div:last-child {
  /*border:1px solid #fff;*/
  /* 定宽 */
  width: 73%;
}
</style>
