<template>
  <div class="title-container" :class="genBgImg()">
    <div class="logo-container">
      <div class="img-container">
        <img :src="logoImg" v-if="logoImg" />
      </div>
    </div>
    <div>
    </div>
    <div>
      <div>
        <p>
          <img :src="picture" alt="" />
          <span>{{ temp }}°C </span>
          {{ year }}-{{ month }}-{{ date }} 星期{{ day }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {getWeatherDataApi} from '@/api/api'
import { getIPAddress } from '@/utils/index.js'

export default {
  data() {
    return {
      // 当前时间
      year: 'YYYY',
      month: 'MM',
      date: 'DD',
      day: 'd',
      // 温度
      temp: '',
      // 天气图片
      picture: '',
      logoImg: null
    }
  },
  methods: {
    // 获取天气数据
    get_temp() {
      const params = {
        sysInsCode: 'XM_64654da8badc4026a54bedef7ea8b54e',
        type: 'HnWeatherData'
      }
      getWeatherDataApi(params).then((res) => {
        const { temp, icon } = res.result.data.condition
        this.temp = temp
        // 动态改变图片(piblic中的不需要写require)
        this.picture = require(`@/assets/images/weather/W${icon}.png`)
      })
      .catch((e) => {
        console.log(e.message)
      })
    },
    genBgImg() {
      const IPAddress = getIPAddress()
      if(IPAddress.indexOf('hyhncloud') > -1) {
        return 'hn-logo'
      } else {
        return 'no-logo'
      }
    }
  },
  mounted() {
    // 获取当前时间
    // 年
    this.year = new Date().getFullYear()
    // 月
    let MM = new Date().getMonth() + 1
    if (MM < 10) {
      this.month = `0${MM}`
    } else {
      this.month = MM
    }
    // 日
    let DD = new Date().getDate()
    if (DD < 10) {
      this.date = `0${DD}`
    } else {
      this.date = DD
    }
    // 星期
    let week = ['日', '一', '二', '三', '四', '五', '六']
    let dd = new Date().getDay()
    this.day = week[dd]

    // 获取天气数据
    this.get_temp()

    const IPAddress = getIPAddress()
    if(IPAddress.indexOf('hyhncloud') > -1) {
      this.logoImg = require(`@/assets/images/logo.svg`)
    }
  }
}
</script>


<style scoped lang="less">
.title-container {
  display: flex;
  height: 4.7rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center bottom;
  align-items: flex-end;
  .logo-container {
    flex-grow: 1.12;
    background-repeat: no-repeat;
    height: 4.7rem;
    .img-container {
      width: 8.75rem;
      height: 2.5rem;
      margin-top: 2.5rem;
      margin-left: 1.25rem;
      &>img {
        width: 100%;
        height: 100%;
      }
    }
  }
  & > div:nth-child(2) {
    width: 28rem;
    height: 4.7rem;
    font-size: 1.5rem;
    color: #ffffff;
    // & > p {
    //   letter-spacing: 0.3rem;
    //   /* border: 1px solid rgb(224, 134, 15); */
    //   font-size: 1.5rem;
    //   font-weight: 600;
    //   margin-top: 1.1rem;
    //   /* 渐变色字体 */
    //   background-image: -webkit-linear-gradient(top, #106feb, #fff, #106feb);
    //   /* background-image: linear-gradient(to bottom,#0A162C 0%,#0B1C35 50%,#0B2242 100%); */
    //   /* background-image: -moz-linear-gradient(top,#0A162C 0%,#0B1C35 50%,#0B2242 100%);
    //     /* background-image: -o-linear-gradient(top,#0A162C 0%,#0B1C35 50%,#0B2242 100%);
    //     background-image: -ms-linear-gradient(top,#0A162C 0%,#0B1C35 50%,#0B2242 100%); */
    //   -webkit-background-clip: text;
    //   -webkit-text-fill-color: transparent;
    // }
  }
  & > div:nth-child(3) {
    flex-grow: 0.89;
    background-repeat: no-repeat;
    background-position: 89.5%;
    height: 4.7rem;
    text-align: right;
    & > div {
      margin-top: 2.7rem;
      margin-right: 2rem;
      color: #fff;
    }
    img {
      height: 1.5rem;
      vertical-align: bottom;
    }
    p {
      letter-spacing: 0.05rem;
    }
    span {
      margin: 0rem 1rem 0rem;
    }
  }
}
.hn-logo {
  background-image: url('~@/assets/images/title.gif');
}
.no-logo {
  background-image: url('~@/assets/images/title-nologo.gif');
}
</style>
