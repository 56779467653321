<template>
  <div style="height: 210px;overflow: hidden;width: 100%;">
    <div class="box2" style="position: relative">
        <div style="position: absolute;top: 88px;left: 50%;margin-left: -20px;color: #A3D1FF;">所有项目</div>
        <pro-distribute-chart :AData="CircleDate7.AData" :BData="CircleDate7.BData" :key="trem"></pro-distribute-chart>
    </div>
  </div>
</template>
<script>
// import * as method from "@/api";
import {getProjectCountByProvinceApi} from "@/api/api";
import ProDistributeChart from './ProDistributeChart.vue';
export default {
    components:{ProDistributeChart},
    data(){
        return{
            CircleDate7:{
                AData:[
                    // {name:'物联', value:0},
                    // {name:'数据', value:0},
                    // {name:'应用', value:0},
                ],
                LXl:'0%',
                NowLxcount:'0次',
                totaltime:'0小时',
                BData:[
                    // {name:'北京', value:0},
                    // {name:'贵州', value:0},
                    // {name:'黑龙江', value:0,},
                    // {name:'陕西', value:0},
                    // {name:'山东', value:0},
                    // {name:'内蒙古', value:0,},
                ]
            },
          trem: 1
        }
    },
  methods:{
    getProjectCountByProvince() {
      getProjectCountByProvinceApi({
        tenantId: "",//查询全部时为 ""
        sysInsCode:'HN_f23e0f8d71e8473894df15fb54811c36',
      }).then(res => {
        // this.CircleDate7.AData = res.result.inSite
        this.CircleDate7.AData = [
          {name: "北京", value: "3"},
          {name: "福建", value: "1"},
          {name: "贵州", value: "1"},
          {name: "海南", value: "1"},
          {name: "河北", value: "1"},
          {name: "黑龙江", value: "1"},
          {name: "辽宁", value: "3"},
          {name: "内蒙", value: "16"},
          {name: "山东", value: "6"},
          {name: "陕西", value: "5"},
          {name: "浙江", value: "2"}
        ]
        // this.CircleDate7.BData = res.result.outSite
        this.CircleDate7.BData = [
          {name: "北京", value: "3"},
          {name: "福建", value: "1"},
          {name: "贵州", value: "1"},
          {name: "海南", value: "1"},
          {name: "河北", value: "1"},
          {name: "黑龙江", value: "1"},
          {name: "辽宁", value: "3"},
          {name: "内蒙", value: "16"},
          {name: "山东", value: "6"},
          {name: "陕西", value: "5"},
          {name: "浙江", value: "2"}
        ]
        // this.CircleDate7.BData.length = 6
        this.trem++
      }).catch((e) => {
          console.log(e.message)
        })
    }
  },
  mounted() {
    this.getProjectCountByProvince()
  }
}
</script>
<style lang="less" scoped>
.box2{
    //background-color:#22242c;
    width: 100%;
    height: 300px;
    border-radius: 5px;
    margin-bottom: 16px;
    box-sizing: border-box;
}
</style>
