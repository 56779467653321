<template>
  <div class="center-f">
    <div>
      <p>统计时间：2021-01-01 一 {{ date2 }}</p>
    </div>
    <div>
      <div v-for="(type, index) of count" :key="index" class="five">
        <p>
          {{ types[index].text }}
        </p>
        <p>
          <span v-if="tip && index === 0"
            >{{ count[index] }} <span class="count-color">个</span></span
          >
          <span v-if="tip && index === 1"
            >{{ count[index] }} <span class="count-color">台</span></span
          >
          <span v-if="tip && index === 2"
            >{{ count[index] }} <span class="count-color">万t</span></span
          >
          <span v-if="tip && index === 3"
            >{{ count[index] }} <span class="count-color">万tce</span></span
          >
          <span v-if="tip && index === 4"
            >{{ count[index] }} <span class="count-color"> 亿元</span></span
          >
          {{ type.unit }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as that from '@/utils'
import axios from 'axios'
import {
  getTodayEnergyApi,
  getProjectInvestmentApi,
  getSysCarbonEmissionsApi
} from '@/api/api'

export default {
  data() {
    return {
      // 开始监测时间
      date1: '2020-12-15',
      // 结束监测时间
      year2: 'YYYY',
      month2: 'MM',
      date2: that.format(new Date()),
      // 顶部五个类别
      types: [
        { text: '接入项目数', unit: 'MWh' },
        { text: '物联接入设备设施', unit: 'MWh' },
        { text: '碳排放总量', unit: '台' },
        { text: '综合用能', unit: '万元' },
        { text: '改造项目总投资', unit: '万元' }
      ],
      // 获取到的数据
      count: ['40', '8325', '-', '-', '-'],
      countNew: [],
      tip: false
    }
  },
  methods: {
    getStationCumulant() {
      axios.get('http://lw.hyhncloud.cn:8830/lwjk/monitor/getStationCumulant',
        {params: {
          systemInsCode: 'GR_74ea73d1f1244287a97fb26ae5fdbbbc',
          tenantId: 30,
          gatewaycode: 'SiACT20200528V60008'
          }}
        ).then((res) => {
        this.item = res.result
      }).catch(e => {
        console.error(e.message)
      })
    },
    getTodayEnergy() {
      const params = {
        useMeans: 'suppy',
        time: that.getYear(new Date()),
        mediumCodes: 'ActivePower',
        sysInsCode: 'HN_f23e0f8d71e8473894df15fb54811c36',
        tenantId: ''
      }
      getTodayEnergyApi(params)
        .then((res) => {
          // this.$set(this.count, 3, res.result?.ActivePower || '-')
          this.$set(this.count, 3, 4351)
        })
        .catch((e) => {
          console.error(e.message)
        })
    },
    getProjectInvestment() {
      const params = {
        sysInsCode: 'HN_f23e0f8d71e8473894df15fb54811c36',
        tenantId: this.$store.state.tenantId,
        useMeans: 'use'
      }
      getProjectInvestmentApi(params)
        .then((res) => {
          // this.$set(this.count, 4, res.result?.projectInvestment || '-')
          this.$set(this.count, 4, 15.15)
        })
        .catch((e) => {
          console.error(e.message)
        })
    },
    getSysCarbonEmissions() {
      const params = {
        projectCode: 'HN_f23e0f8d71e8473894df15fb54811c36',
        itemNameCode: 'ActivePower',
        useMeans: 'use'
      }
      getSysCarbonEmissionsApi(params)
        .then((res) => {
          // this.$set(this.count, 2, res.result?.sysCarbonEmissions || '-')
          this.$set(this.count, 2, 12832)
          this.tip = true
        })
        .catch((e) => {
          console.error(e.message)
        })
    }
  },
  mounted() {
    // 查询接入项目数
    this.getStationCumulant()
    // 碳排放总量
    this.getSysCarbonEmissions()
    // 综合用能
    this.getTodayEnergy()
    // 改造项目总投资
    this.getProjectInvestment()
  }
}
</script>
<style scoped lang="less">
.center-f {
  width: 59.35rem;
  margin: 0 auto;
  padding-top: 0.2rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  & > div:first-child {
    color: #fff;
    text-align: right;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
  & > div:first-child > p {
    letter-spacing: 0.08rem;
  }
  & > div:nth-child(2) {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-image: url('~@/assets/images/center/上部底.png');
    background-repeat: no-repeat;
    height: 4.9rem;
    display: flex;
    justify-content: space-between;
  }
  .five {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 20%;
    color: #fff;
    font-size: 0.875rem;
  }
  .five > p {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 50%;
    line-height: 2.35rem;
  }
  .five > p > span {
    font-size: 1rem;
    font-weight: 600;
    color: #0092fe;
  }
  .count-color {
    color: #a3d1ff;
    font-weight: 500;
    font-size: 14px;
  }
}
</style>
