<template>
  <div class="structure-container">
    <el-row
      type="flex"
      class="row-bg"
      justify="space-around"
      style="margin-top: 20px;"
    >
      <el-col :span="2"></el-col>
      <el-col :span="6">
        <div class="grid-content">
          <img :src="require('@/assets/brokenline03.png')" alt="">
          <!-- <p><span style="color: #ff5859;"> {{item.totalHeat}} </span>tce/m³</p> -->
          <p><span style="color: #ff5859;"> 34.9 </span>W/㎡</p>
          <P>单位面积耗热</P>
        </div>
      </el-col>
      <!-- <el-col :span="6">
        <div class="grid-content">
          <img :src="require('@/assets/brokenline02.svg')" alt="">
          <p><span style="color: #178CFF;">{{item.totalWater}} </span>m³</p>
          <P>耗水</P>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <img :src="require('@/assets/brokenline01.svg')" alt="">
          <p><span style="color: #20DBB4;">{{item.activePower}} </span>m³</p>
          <P>耗电</P>
        </div>
      </el-col> -->
      <el-col :span="2"></el-col>
    </el-row>
    <div
      class="echart-container"
      :id="echarts"
      ref="echarts"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  props:{
    item: {
      type: Object,
      default: () => {
        return {
          totalHeat: '',
          totalWater: '',
          activePower: ''
        }
      }
    },
    inseriesData: {
      type: Array,
      default: () => []
    },
    xAxisData: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    echarts() {
      return 'echarts' + Math.random()*100000
    },
  },
  methods: {
    // 使用echarts.init这个方法来创建一个 ECharts 实例，返回 echartinstance，不能在单个容器上初始化多个 ECharts 实例,因此需要用到Vue的computed属性来解决这个问题
    // 因为把它封装成了一个Vue组件,因此要注意父子组件之间的通信、需要用到props这个属性
    // 在Vue的生命周期mounted执行，并且在this.$nextTick里面执行这个方法，保证承若所有的子组件被挂载、能保证通过获取到组件
    drawChart () {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById(this.echarts))
      // 绘制图表
      myChart.setOption({
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                type: 'dashed ',
                color: '#999'
              }
            },
            data: this.xAxisData
          }
        ],
        yAxis: [
          {
            name: 'W/㎡',
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                type: 'dashed ',
                color: '#999'
              }
            },
            splitLine: {
              show: false
            },
          },
        ],
        series: [
          {
            name: '搜索引擎',
            type: 'line',
            symbol: 'circle', // 拐点类型
            smooth: true, // 当为true时，就是光滑的曲线（默认为true）；当为false，就是折线不是曲线的了，那这个设为true，下面的（吃饭）数据中设置smooth为false，这个就不是光滑的曲线了。
            symbolSize: 3, // 拐点圆的大小
            // areaStyle: {
            //   normal: {
            //     color: '#095252' //改变区域颜色
            //   }
            // },
            itemStyle : {
              normal : {
                color: '#FFDE58', //改变折线点的颜色
                lineStyle: {
                  color: '#FFDE58' //改变折线颜色
                }
              }
            },
            // data: [0,0.5,1,1.5,2,2.5,3,3.5,4,4.5,5,5.5,6,6.5,7,7.5,8]//this.inseriesData
            data: ["31.52", "32.17", "34.02", "33.21", "34.78", "32.50", "25.58", "26.82", "27.89", "28.06", "27.47", "25.53", "24.40", "26.61", "26.83", "28.41", "26.32", "26.87", "30.15", "29.48", "32.89", "30.39", "31.68", "33.91"]
          }
        ]
      }, true)
      setTimeout(function (){
        window.addEventListener('resize', ()=> {
          myChart.resize();
        })
      }, 200)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawChart()
    })
  }
}
</script>

<style lang="less" scoped>
.structure-container {
  height: 400px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  .title {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    margin-left: 20px;
  }
  .structure-chart {
    flex: 1;
  }
  .structure-info {
    height: 60px;
    p {
      height: 20px;
      line-height: 25px;
      font-size: 12px;
      color: #ffffff;
    }
    .legend-block {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .square {
    display: inline-block;
    width: 10px;
    height: 10px;
  }
  .color-one {
    background: #3dc7ef;
  }
  .color-two {
    background: #dcef3d;
  }
  .color-three {
    background: #ef3d3d;
  }
  .color-four {
    background: #3def88;
  }
  .legend-font {
    font-size: 10px;
    margin-left: 8px;
    color: #ffffff;
  }
  .mal20 {
    margin-left: 20px;
  }
  .echart-container {
    width: 100%;
    height: 280px;
    position: absolute;
    top: 50px;
  }
}
.grid-content{
  font-size: 14px;
  color: #ccc;
  p{
    line-height: 24px;
  }
}
</style>
