// 切割过长的字符串（以字符为单位）
export function wrapStr(value, len) {
    let leng = 0;
    for (var i = 0; i < value.length; i++) {
        var a = value.charAt(i);
        if (a.match(/[^\x00-\xff]/ig) != null) {
            leng += 2;
        } else {
            leng += 1;
        }
    }
    if (leng / 2 > len) {
        return value.substring(0, len - 1) + '...'
    } else {
        return value
    }
}

// 切割过长的字符串（以字节为单位）
export function wrapStrByByte(value, len) {
    let result = ''
    value = value.toString()
    let strlen = value.length
    let chrlen = value.replace(/[^x00-\xff]/g, '**').length
    if (chrlen <= len) {
        return value
    }
    for (let i = 0, j = 0; i < strlen; i++) {
        let chr = value.charAt(i)
        if (/[x00-\xff]/.test(chr)) {
            j++
        } else {
            j += 2
        }
        if (j <= len) {
            result += chr
        } else {
            return result + '...'
        }
    }
}
