<template>
  <div class="table-b">
    <p>通知</p>
    <div class="table-out">
      <table>
        <tr>
          <td></td>
          <td>项目</td>
          <td>通知时间</td>
          <td>能源站</td>
          <td>通知内容</td>
          <td>操作</td>
        </tr>
        <tr
          v-for="(i, index) of info"
          :key="index"
          ref="tableNotice"
          :class="{ act: i.confirm }"
        >
          <td>
            <img :src="require('@/assets/images/center/icon_speaker.png')" alt="">
          </td>
          <td :title="i.projectName">{{ i.projectName | wrapStr(13) }}</td>
          <td>{{ i.happenedTime }}</td>
          <td :title="i.subItemName">{{ i.subItemName | wrapStr(7) }}</td>
          <td :title="i.noticeDesc">{{ i.noticeDesc | wrapStr(26) }}</td>
          <td @click="actived($event, index)">确认</td>
        </tr>
      </table>
    </div>
  </div>
</template>


<script>
import { getConfirmNoticeApi, getNoticeApi } from '@/api/api'

export default {
  data() {
    return {
      info: []
    }
  },
  methods: {
    // 当前行置灰
    actived(e, index) {
      let td = e.target
      let tr = td.parentNode
      if (tr.className !== 'act') {
        let defaultParams = {
          id: this.info[index].id,
          confirm: 1
        }
        getConfirmNoticeApi(defaultParams)
          .then((res) => {
            const { state } = res.result
            if (state == 1) {
              tr.className = 'act'
            } else if (state == 99) {
              window.alert('通知状态暂未修改')
            }
          })
          .catch((e) => {
            console.log(e.message)
          })
      }
      //不可以取消确认
      // else{
      //   // 发送请求到数据库中取消确认
      //   let defaultParams={
      //     id:this.info[index].id,
      //     confirm:0
      //   }
      //   // console.log(defaultParams);
      //   this.axios.post("/hnReadDate/visual/confirmNotice",{
      //   params:defaultParams
      // }).then((res)=>{
      //     console.log(res.data);
      //     if(res.data.result.state==1){
      //       tr.className = ""
      //     }else if(res.data.result.state==99){
      //       window.alert("通知状态暂未修改")
      //     }
      //   })
      // }
    },
    getTableData() {
      const params = {
        startTime: '',
        endTime: ''
      }
      getNoticeApi(params).then((res) => {
        this.info = res.result
      }).catch((e) => {
        console.log(e.message)
      })
    }
  },
  mounted() {
    this.getTableData()
  }
}
</script>

<style scoped lang="less">
.table-b {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-image: url('~@/assets/images/center/通知底.png');
  background-repeat: no-repeat;
  height: 13.5rem;
  width: 60.3rem;
  margin: 0 auto;
  & > p {
    color: #fff;
  }
  .table-out {
    margin-top: 1.5rem;
    height: 9.43rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      height: 0.2rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.625rem;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #3d93f0;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ededed;
    }
    table {
      color: #fff;
      font-size: 0.875rem;
      width: 95%;
      margin: 0 auto;
      border-collapse: collapse;
      text-align: left;
    }
    table > tr {
      height: 1.875rem;
    }
    table img {
      margin-left: 0.8rem;
    }
    table > tr > td:nth-child(1) {
      width: 4%;
    }
    table > tr > td:nth-child(2) {
      width: 22%;
    }
    table > tr > td:nth-child(3) {
      width: 16%;
    }
    table > tr > td:nth-child(5) {
      width: 38%;
      padding-right: 5px;
    }
    table > tr > td:nth-child(4) {
      width: 12%;
    }
    table > tr > td:last-child:hover {
      color: #575dcb;
      cursor: pointer;
    }
    table > tr:first-child {
      background-color: rgba(27, 126, 202, 0.3);
      border: 1px solid rgb(39, 151, 255);
      border-left: 0;
      border-right: 0;
    }
    .act {
      background-color: rgba(99, 95, 95, 0.6);
    }
    .act td:last-child {
      color: #999 !important;
    }
  }
}
</style>
